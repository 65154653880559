import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { GlobalApplicationState } from 'globalApplicationState';
import * as actions from '../actionCreator';
import { ReactionTab } from '../models';
import ToggleSwitch from 'modules/common/components/toggleSwitch';
import { errorAlert } from 'utils/notyPopups';

const SystemMaxCommentLength = 1000;

class GeneralSettingsForm extends React.Component<PropsWithRedux, {}> {

    private maxReactionCount = 6;

    public render() {
        const working = this.props.saving || this.props.fetching;

        const enabledReactions = this.props.tenantSettings.reactions.filter(reaction => reaction.enabled)

        const disabledReactions = this.props.tenantSettings.reactions.filter(reaction => !reaction.enabled)

        const showingEnabledReactions = this.props.activeReactionTab === ReactionTab.Enabled;

        const reactionsEnabledClass = "form-group group-" + (this.props.tenantSettings.reactionsEnabled ? 'visible' : 'hidden')
        const commentsEnabledClass = "form-group group-" + (this.props.tenantSettings.commentsEnabled ? 'visible' : 'hidden')

        return <div>
            <div className="form-section">
                <h4>Reactions</h4>
                <div className="tabs">
                    <ul className="tab-btns">
                        <li>
                            <span onClick={() => { this.props.setActiveReactionTab(ReactionTab.Enabled); } }
                                className={'tab-btn' + (showingEnabledReactions ? ' active' : '')}>
                                Enabled ({enabledReactions.length})
                            </span>
                        </li>
                        <li>
                            <span onClick={() => { this.props.setActiveReactionTab(ReactionTab.Disabled); } }
                                className={'tab-btn' + (!showingEnabledReactions ? ' active' : '')}>
                                Disabled ({disabledReactions.length})
                            </span>
                        </li>
                    </ul>
                    <div className={'tab' + (showingEnabledReactions ? ' active' : '')}>
                        <div className="form-group" style={{marginBottom: "0px"}}>
                            {!enabledReactions.length
                            ? 'All reactions are currently disabled'
                            : <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Name</th>
                                            <th>Enabled</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            enabledReactions.map((reaction, index, reactions) => {
                                                return <tr key={reaction.id}>
                                                    <td>
                                                        {
                                                            reaction.enabled ?
                                                            <div className={'sort-buttons ' + (index === 0 ? 'first' : '') + (index === reactions.length - 1 ? 'last' : '')}>
                                                                <button className="sort-btn sort-up"
                                                                    onClick={(e) => { this.props.moveReaction(reaction.id, true) }}
                                                                    disabled={working}>▲</button>
                                                                <button className="sort-btn sort-down"
                                                                    onClick={(e) => { this.props.moveReaction(reaction.id, false) }}
                                                                    disabled={working}>▼</button>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                    <td>
                                                        <span className="icon-sentiment" title={'Sentiment: ' + reaction.sentiment}>
                                                            <img src={`/images/reactions/${reaction.sentiment}_reaction.png`}
                                                                alt={reaction.sentiment}
                                                                style={{ height: '32px' }} />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <input className="form-control"
                                                            type="text"
                                                            value={reaction.name}
                                                            readOnly={working}
                                                            onChange={(e) => { this.handleReactionNameChange(reaction.id, e); } } />
                                                    </td>
                                                    <td>
                                                        <button onClick={() => { this.enableReaction(reaction.id, false) } }
                                                            disabled={working}>Disable</button>
                                                    </td>
                                                </tr>
                                            })}
                                    </tbody>
                                </table>
                                <div style={{fontSize: "14px", marginTop: "15px", marginLeft: "15px", color: "#aaa"}}>
                                Limit {this.maxReactionCount}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className={'tab' + (!showingEnabledReactions ? ' active' : '')}>
                        {!disabledReactions.length
                        ? 'All reactions are currently enabled'
                        : <div className="form-group">
                            <table>
                                <tbody>
                                    {
                                        disabledReactions.map((reaction, index, reactions) => {
                                            return <tr key={reaction.id}>
                                                <td>
                                                    <span className="icon-sentiment">
                                                        <img src={`/images/reactions/${reaction.sentiment}_reaction.png`}
                                                            alt={reaction.sentiment}
                                                            style={{ height: '32px' }} />
                                                    </span>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => { this.enableReaction(reaction.id, true) } }
                                                        disabled={this.props.saving}>Enable</button>
                                                </td>
                                                <td>{reaction.name}</td>
                                            </tr>
                                        })}
                                </tbody>
                            </table>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="form-section">
                <h4>Reaction Toggles</h4>
                <div className="form-group">
                    <label>Reactions enabled on posts</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.reactionsEnabled}
                            onClick={() => { this.props.setReactionsEnabled(!this.props.tenantSettings.reactionsEnabled); } }
                            disabled={working} />
                    </div>
                </div>
                <div className={reactionsEnabledClass}>
                    <label>Enable reacting for new posts by default</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.isReactingEnabledForNewPostsByDefault}
                            onClick={() => { this.props.setIsReactingEnabledForNewPostsByDefault(!this.props.tenantSettings.isReactingEnabledForNewPostsByDefault); } }
                            disabled={working} />
                    </div>
                </div>
                <div className="form-group">
                    <label>Allow contributors to enable/disable reactions on post submissions</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.canContributorsToggleReactions}
                            onClick={() => { this.props.setCanContributorsToggleReactions(!this.props.tenantSettings.canContributorsToggleReactions); } }
                            disabled={working} />
                    </div>
                </div>
                <h4>Comments</h4>
                <div className="form-group">
                    <label>Commenting enabled on posts</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.commentsEnabled}
                            onClick={() => { this.props.setCommentsEnabled(!this.props.tenantSettings.commentsEnabled); } }
                            disabled={working} />
                    </div>
                </div>
                <div className={commentsEnabledClass}>
                    <label>Enable commenting for new posts by default</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.isCommentingEnabledForNewPostsByDefault}
                            onClick={() => { this.props.setIsCommentingEnabledForNewPostsByDefault(!this.props.tenantSettings.isCommentingEnabledForNewPostsByDefault); } }
                            disabled={working || !this.props.tenantSettings.commentsEnabled} />
                    </div>
                </div>
                <div className={commentsEnabledClass}>
                    <label>Enable GIF selection on comments</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.gifSelectionEnabledForComments}
                            onClick={() => { this.props.setGifSelectionEnabledForComments(!this.props.tenantSettings.gifSelectionEnabledForComments); } }
                            disabled={working || !this.props.tenantSettings.commentsEnabled} />
                    </div>
                </div>
                <div className={commentsEnabledClass}>
                    <label>Allow Owners to delete comments</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.commentModerationEnabled}
                            onClick={() => { this.props.setIsCommentModerationEnabled(!this.props.tenantSettings.commentModerationEnabled); } }
                            disabled={working || !this.props.tenantSettings.commentsEnabled} />
                    </div>
                </div>
                <div className="form-group">
                    <label>Allow contributors to enable/disable comments on post submissions</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.canContributorsToggleComments}
                            onClick={() => { this.props.setCanContributorsToggleComments(!this.props.tenantSettings.canContributorsToggleComments); } }
                            disabled={working} />
                    </div>
                </div>
                <div className="form-group">
                    <label>Comment max length</label>
                    <div className="input-group">
                        <input className="form-control"
                            min={0}
                            max={SystemMaxCommentLength}
                            type="number"
                            value={this.props.tenantSettings.commentMaxLength}
                            readOnly={working}
                            onChange={this.handleCommentMaxLengthChange} />
                        <span className="add-on">{`characters (Max. ${SystemMaxCommentLength})`}</span>
                    </div>
                </div>
            </div>
            <div className="form-section">
                <h4>Users</h4>
                <label>Total user count</label>
                <div className="form-group">
                    <span className="add-on">{`${this.props.tenantSettings.totalUserCountForReporting}`}</span>
                </div>


            </div>
            {/* for some tenant's with a custom url portal, we handle the prefix behind the scenes  */}
            {this.props.tenantSettings.localAccountsEnabled &&
                window.location.host.split(".")[0].toLowerCase() !== this.props.tenantSettings.localAccountPrefix.toLowerCase() &&
            <div className="form-section">
                <h4>Local Accounts</h4>
                <div className="form-group">

                <label>Local Account Id Prefix</label>
                <div className="form-group">
                    <span className="add-on">{`${this.props.tenantSettings.localAccountPrefix}`}</span>
                </div>
                </div>
            </div>

            }
            <div className="form-section">
                <h4>External Post Sources</h4>
                <div className="input-group" style={{display: 'grid'}}>
                    <label>Days for automatic purging</label>

                    <p className="disclaimer" style={{maxWidth: "600px"}}>
                        Set this to determine how many days it will take for external draft posts to automatically delete.
                    </p>
                    <div style={{display: 'flex', maxHeight: 28, height: 100}}>
                        <ToggleSwitch value={this.props.tenantSettings.externalPostPurgeEnabled}
                            onClick={() => { this.props.setExternalPostPurgeEnabled(!this.props.tenantSettings.externalPostPurgeEnabled); } }
                            disabled={working} />

                        <input
                        type="number"
                        min={0}
                        value={this.externalPostPurgeInDaysWait()}
                        readOnly={working}
                        onChange={this.handleExternalPostPurgeInDaysChange} />
                    </div>

                </div>
            </div>
            <div className="form-section">
                <h4>Managing Topics and Audiences</h4>
                <div className="form-group" style={{display: 'grid'}}>
                    <label>Manage Topics and Audiences in the Admin Portal</label>
                    <div>
                        <ToggleSwitch value={this.props.tenantSettings.manageTopicsAudiencesInAdmin}
                            onClick={() => { this.props.setManageTopicsAudiencesInAdmin(!this.props.tenantSettings.manageTopicsAudiencesInAdmin); } }
                            disabled={working} />
                    </div>
                    <p className="disclaimer" style={{maxWidth: "600px"}}>
                        Topics and audiences will be managed from SharePoint or Teams if disabled.
                    </p>
                </div>
            </div>
            <div className="form-section">
                <h4>Posts</h4>
                <div className={'form-group'}>
                    <label>Automatically Expire Old Mandatory Posts</label>
                    <div>
                        <ToggleSwitch value={this.hasMandatoryExpiryTime()}
                            onClick={this.props.toggleMandatoryExpiryTime }
                            disabled={working} />
                    </div>
                </div>
                {
                    this.hasMandatoryExpiryTime() &&
                    <div className="form-group">
                        <label>Mandatory posts will no longer be pushed to the top of the post feed after this number of days</label>
                        <p style={{"fontSize": "12px"}}></p>
                        <div className="input-group">
                            <input className="form-control"
                                min={1}
                                type="number"
                                value={this.props.tenantSettings.mandatoryExpiryTime}
                                readOnly={working}
                                onChange={this.handleMandatoryExpiryTimeChange} />
                            <span className="add-on">days</span>
                        </div>
                    </div>
                }
            </div>
            <div className="form-section">
                <h4>Support</h4>
                <div className={'form-group'} style={{display: 'grid'}}>
                    <label>Support Email</label>
                    <p className="disclaimer" style={{maxWidth: "600px"}}>
                        User issues, requests, and feedback will be sent to this email.
                    </p>
                    <div className="input-group">
                        <input className="form-control"
                            style={{width: '310px'}}
                            type="email"
                            placeholder={"feedback@example.com"}
                            value={this.props.tenantSettings.supportEmail}
                            readOnly={working}
                            onChange={this.handleSupportEmailChange} />
                    </div>
                </div>
            </div>


            <div className="form-section">
                <h4>Banners</h4>
                <div className={'form-group'}>
                    <label>Show Restricted Banner</label>
                    <div>
                        <ToggleSwitch
                            value={this.props.tenantSettings.bannerVisibility.showRestrictedBanner}
                            onClick={() => { this.props.setRestrictedBannerEnabled(!this.props.tenantSettings.bannerVisibility.showRestrictedBanner)}}
                            disabled={working} />
                    </div>
                </div>
            </div>
        </div>
    }


    private NotificationWait = () => {
        let ts = this.props.tenantSettings;
        let propExists = ts && ts.flaggingSettings;
        if(propExists && isNaN(ts.flaggingSettings.minimumNotificationWait)){
            return NaN;
        }
        return propExists ? ts.flaggingSettings.minimumNotificationWait || 30 : 30;
    }

    private externalPostPurgeInDaysWait = () => {
        let ts = this.props.tenantSettings;
        let propExists = ts && ts.flaggingSettings;

        return propExists ? ts.externalPostPurgeInDays : 0;
    }

    private flaggingNotificationWaitChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let input = e.target as HTMLInputElement;
        let value = parseInt(input.value, 10);
        this.props.setFlaggingNotificationWait(value);
    }

    private hasMandatoryExpiryTime = (): boolean => {
        const ts = this.props.tenantSettings;
        //check NaN so user can empty the number input without making the form disappear
        return !!(ts && (ts.mandatoryExpiryTime || (isNaN(ts.mandatoryExpiryTime) && typeof ts.mandatoryExpiryTime !== "undefined")))
    }

    private handleExternalPostPurgeInDaysChange = (e: React.FormEvent<HTMLInputElement>) => {
        let input = e.target as HTMLInputElement;
        let value = parseInt(input.value, 10);
        this.props.setExternalPostPurgeInDays(value)
    }

    private handleMandatoryExpiryTimeChange = (e: React.FormEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement;
        const value = parseInt(input.value, 10); //can be NaN when form is empty
        this.props.setMandatoryExpiryTime(value);
    }

    private handleCommentMaxLengthChange = (e: React.FormEvent<HTMLInputElement>) => {
        let input = e.target as HTMLInputElement;
        let value = parseInt(input.value, 10);
        value = Math.min(value, SystemMaxCommentLength)
        this.props.setCommentMaxLength(value);
    }

    private handleReactionNameChange = (id: any, e: React.FormEvent<HTMLInputElement>) => {
        let input = e.target as HTMLInputElement;
        this.props.setReactionName(id, input.value);
    }

    private handleSupportEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
        let input = e.target as HTMLInputElement;
        this.props.setSupportEmail(input.value);
    }

    private enableReaction = (reactionId, value) => {
        if (value && this.props.tenantSettings.reactions.filter(reaction => reaction.enabled).length >= this.maxReactionCount) {
            errorAlert("Only 6 enabled reactions are permitted");
        }
        else {
            this.props.setReactionEnabled(reactionId, value)
        }
    }
}

interface ComponentProps {}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        fetching: state.settings.fetching,
        saving: state.settings.saving,
        lcids: Object.keys(state.settings.tenantSettings.translatedContent).sort(),
        lcidMappings: state.resources.lcidMappings,
        tenantSettings: state.settings.tenantSettings,
        activeReactionTab: state.settings.activeReactionTab
    }),
    {
        setShowInvitedExternalUsers: actions.setShowInvitedExternalUsers,
        setPostNotificationsEnabled: actions.setPostNotificationsEnabled,
        setSmsNotificationsEnabled: actions.setSmsNotificationsEnabled,
        setEmailNotificationsEnabled: actions.setEmailNotificationsEnabled,
        setCommentsEnabled: actions.setCommentsEnabled,
        setReactionsEnabled: actions.setReactionsEnabled,
        setIsReactingEnabledForNewPostsByDefault: actions.setIsReactingEnabledForNewPostsByDefault,
        setCanContributorsToggleReactions: actions.setCanContributorsToggleReactions,
        setCanContributorsToggleComments: actions.setCanContributorsToggleComments,
        setManageTopicsAudiencesInAdmin: actions.setManageTopicsAudiencesInAdmin,
        setExternalPostPurgeEnabled: actions.setExternalPostPurgeEnabled,
        setEveryoneAudienceEnforcementEnabled: actions.setEveryoneAudienceEnforcementEnabled,
        setIsCommentingEnabledForNewPostsByDefault: actions.setIsCommentingEnabledForNewPostsByDefault,
        setIsCommentModerationEnabled: actions.setCommentModerationEnabled,
        setGifSelectionEnabledForComments: actions.setGifSelectionEnabledForComments,
        setCommentMaxLength: actions.setCommentMaxLength,
        setReactionEnabled: actions.setReactionEnabled,
        setReactionName: actions.setReactionName,
        moveReaction: actions.moveReaction,
        setActiveReactionTab: actions.setActiveReactionTab,
        setResetLocalUserPassword: actions.setResetLocalUserPassword,

        setExternalPostPurgeInDays: actions.setExternalPostPurgeInDays,
        setMandatoryExpiryTime: actions.setMandatoryExpiryTime,
        setFlaggingNotificationWait: actions.setFlaggingNotificationWait,
        toggleMandatoryExpiryTime: actions.toggleMandatoryExpiryTime,
        setSupportEmail: actions.setSupportEmail,
        setRestrictedBannerEnabled: actions.setRestrictedBannerEnabled
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GeneralSettingsForm);
